































































































import { Component, Prop, Watch } from "vue-property-decorator";
import { tasksApi } from "@/api/portal/tasks";
import { Document, DocumentStatus } from "@/models/document";
import { formatIsoDate } from "@/filters";
import DocumentAttachments from "@/components/documents/DocumentAttachments.vue";
import DocumentDetails from "@/components/documents/DocumentDetails.vue";
import Loader from "@/components/Loader.vue";
import { mixins } from "vue-class-component";
import TranslatableComponent, {
  Translatable,
} from "@/lib/mixins/translateable";
import DocumentLayout from "@/components/documents/DocumentLayout.vue";
import { TaskCategory } from "@/models/task";
import Vue from "vue";
import { Role } from "@/models/role";
import { namespace } from "vuex-class";
import { ParticipantType } from "@/enums/ParticipantType";
import magnoliaCmsApi, {
  RepresentativeContactDetails,
} from "@/api/magnoliaCmsApi";
import ContactCardMinimalistic from "@/components/contactCards/ContactCardMinimalistic.vue";
import { Participant } from "@/models/participant";
import TheHeader from "@/components/TheHeader.vue";
import Page from "@/components/layouts/Page.vue";
import Adoption from "@/components/documents/adoption/Adoption.vue";
import InitialState from "@/components/documents/states/InitialState.vue";
import SbrSigningRequiredState from "@/components/documents/states/SbrSigningRequiredState.vue";
import InDraftState from "@/components/documents/states/InDraftState.vue";
import NoOperations from "@/components/documents/states/NoOperations.vue";
import ReadyForSendingState from "@/components/documents/states/ReadyForSendingState.vue";
import SentState from "@/components/documents/states/SentState.vue";
import OwnerSignedState from "@/components/documents/states/OwnerSignedState.vue";
import DeclinedState from "@/components/documents/states/DeclinedState.vue";
import AdoptionRequiredState from "@/components/documents/states/AdoptionRequiredState.vue";
import SignableDocumentsCreatepdfErrState from "@/components/documents/states/SignableDocumentsCreatepdfErrState.vue";
import SignableDocumentsCreatedState from "@/components/documents/states/SignableDocumentsCreatedState.vue";
import SbrSendErrorState from "@/components/documents/states/SbrSendErrorState.vue";
import { RawLocation } from "vue-router/types/router";
import { TaskRedirect } from "@/views/documents/task_redirects";
import { taskReadApi } from "@/api/portal/task-read-api";
import { SseEventSource } from "@/utils/Sse";

const user = namespace("user");

interface RedirectOptions {
  signing: Record<Role, RawLocation>;
  other: any;
}

@Component({
  components: {
    Adoption,
    ContactCardMinimalistic,
    DocumentAttachments,
    DocumentDetails,
    Loader,
    DocumentLayout,
    TheHeader,
    Page,
    initial: InitialState,
    sbr_signing_required: SbrSigningRequiredState,
    in_draft: InDraftState,
    draft_complete: NoOperations,
    ready_for_sending: ReadyForSendingState,
    sent: SentState,
    owner_signed: OwnerSignedState,
    signing_completed: NoOperations,
    declined: DeclinedState,
    adoption_required: AdoptionRequiredState,
    adoption_accepted: NoOperations,
    adoption_rejected: NoOperations,
    signable_documents_created: SignableDocumentsCreatedState,
    signable_documents_createpdf_err: SignableDocumentsCreatepdfErrState,
    sbr_sent: InitialState,
    sbr_send_error: SbrSendErrorState,
    sbr_complete: NoOperations,
    archiving: NoOperations,
    processed: NoOperations,
  },
  methods: { formatIsoDate },
})
@Translatable("documents")
export default class DocumentsShow extends mixins(TranslatableComponent) {
  @Prop({ default: "" })
  private documentId!: string;

  @Prop({ default: "my" })
  private category!: TaskCategory;

  @Prop({ default: true })
  readonly enableAutoRefreshDocument!: boolean;

  @Prop()
  private selectedAccountId: string | undefined;

  @Prop()
  private selectedBranchName: string | undefined;

  @user.State("role")
  private role!: Role;

  private document: Document | null = null;

  private isLoading: boolean = false;

  private sseSource: SseEventSource | null = null;

  private representativeContactDetails: RepresentativeContactDetails | null =
    null;

  private messageBus: Vue = new Vue();

  @user.Getter
  private isCustomer!: boolean;

  @Watch("document")
  private toComponent(): Lowercase<DocumentStatus> | undefined {
    return this.document?.state.toLowerCase() as Lowercase<DocumentStatus>;
  }

  @Watch("document")
  private loadContact(document: any) {
    if (this.isCustomer) this.loadRepresentativeContactDetails(document);
  }

  private loadRepresentativeContactDetails(document: Document | null) {
    const signer = this.findEmployeeSigner(document);
    if (!signer) return;

    magnoliaCmsApi
      .getRepresentativeContactDetails(signer.email)
      .then((response) => {
        this.representativeContactDetails = response.data;
      });
  }

  hasDocument(): boolean {
    return !!this.document;
  }

  get taskCategory(): TaskCategory {
    return this.category;
  }

  get employeeSigner() {
    return this.findEmployeeSigner(this.document);
  }

  mounted() {
    this.reloadData();
    this.sseSource?.close();

    this.sseSource = taskReadApi.taskSse(this.documentId, () => {
      this.loadDocument();
    });
    this.sseSource.connect();
  }

  beforeDestroy() {
    this.sseSource?.close();
  }

  private reloadData() {
    this.loadDocument();
  }

  private loadDocument(): Promise<void> {
    this.isLoading = true;
    return taskReadApi
      .task(this.documentId)
      .then((data) => {
        this.document = data.data;
        return;
      })
      .catch(() => {})
      .finally(() => {
        this.isLoading = false;
      });
  }

  get packageId(): string | null {
    return this.document?.signingInfo?.packageId || null;
  }

  private returnToDocumentList(action: TaskRedirect) {
    switch (action) {
      case "signing":
        const mapping = {
          customer: {
            name: "dashboard",
          },
          employee: {
            name: "documents",
            query: { category: this.taskCategory },
          },
        };
        this.$router.push(mapping[this.role] || mapping["customer"]);
      case "documents":
        this.$router.push({
          name: "documents",
          query: { category: this.taskCategory },
        });
    }
  }

  findEmployeeSigner(document: Document | null): Participant | undefined {
    return document?.participants.find((participant) => {
      return (
        participant.role === "signer" &&
        participant.participantType === ParticipantType.EMPLOYEE
      );
    });
  }
}
